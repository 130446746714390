
import { computed, defineComponent, inject } from 'vue';
import CommentBox from './CommentBox.vue';
import { ReplyIcon, VerifiedIcon } from './Icons';
import { isLinkHttp, timeAgo } from '../utils';

import type { PropType } from 'vue';
import type { ConfigRef } from '../composables';
import type { Comment } from '../typings';

export default defineComponent({
  props: {
    comment: {
      type: Object as PropType<Comment>,
      required: true,
    },
    rootId: {
      type: String,
      required: true,
    },
    reply: {
      type: Object as PropType<Comment | null>,
    },
  },

  components: {
    CommentBox,
    ReplyIcon,
    VerifiedIcon,
  },

  emits: ['submit', 'reply'],

  setup(props) {
    const config = inject<ConfigRef>('config') as ConfigRef;
    const locale = computed(() => config.value.locale);

    const link = computed(() => {
      let { link } = props.comment;

      return link ? (isLinkHttp(link) ? link : `https://${link}`) : '';
    });

    const avatar = computed(() => {
      const userData = props.comment;
      const avatarConfig = config.value.avatar;

      return !userData || avatarConfig.hide
        ? false
        : props.comment.avatar &&
          ('type' in props.comment || avatarConfig.default)
        ? props.comment.avatar
        : `${avatarConfig.cdn}${props.comment.mail}${avatarConfig.param}`;
    });

    const isReplyingCurrent = computed(
      () => props.comment.objectId === props.reply?.objectId
    );

    return {
      config,
      locale,

      avatar,
      isReplyingCurrent,
      link,
      timeAgo,
    };
  },
});
